import * as dat from 'lil-gui'

export let scaleControlValue = 1.6;
export let offsetPositionX = 0, offsetPositionY = 0, offsetPositionZ = -4.2;

export let controllerLineStartX = 0, controllerLineStartY = 0, controllerLineStartZ = 0;
export let controllerLineEndX = 0, controllerLineEndY = 0, controllerLineEndZ = 0;

export function initGui (params) {

    const gui = new dat.GUI()

    const scaleControl = { 
        scale: scaleControlValue
    };

    const controlOffsetPositionX = {
        x: 0
    };
    
    const controlOffsetPositionY = {
        y: 0
    };
    
    const controlOffsetPositionZ = {
        z: 0
    };
    

	// Add the control for scale the tileset
	gui.add(scaleControl, 'scale', -10, 10, 0.01).name('Scale parent').onChange(function (value) {
		// Update the scale of the geospatialRotationParent
        scaleControlValue = value;
	});

    	// Add the control for X position of the tileset
	gui.add(controlOffsetPositionX, 'x', -40, 40, 0.1).name('X position parent').onChange(function (value) {
		offsetPositionX = value;
	});

	// Add the control for Y position of the tileset
	gui.add(controlOffsetPositionY, 'y', -40, 40, 0.1).name('Y position parent').onChange(function (value) {
		offsetPositionY = value;
	});

	// Add the control for Z position of the tileset
	gui.add(controlOffsetPositionZ, 'z', -300, 300, 0.1).name('Z position parent').onChange(function (value) {
		offsetPositionZ = value;
	});

	gui.add(params, 'displayRegionBounds').name('Display Region Bounds').onChange(function(value) {
		// Code to handle the change in displayRegionBounds
		console.log('displayRegionBounds changed to:', value);
	});

	/* controller position */

	/*
	const vertexStart = { x: 0, y: 0, z: 0 };
	const vertexEnd = { x: 0, y: 0, z: 0 };
	
	gui.add(vertexStart, 'x', -1000, 1000, 1).name('Controller Line Start X').onChange(function (value) {
		controllerLineStartX = value;
	});

	gui.add(vertexStart, 'y', -1000, 1000, 1).name('Controller Line Start Y').onChange(function (value) {
		controllerLineStartY = value;
	});

	gui.add(vertexStart, 'z', -1000, 1000, 1).name('Controller Line Start Z').onChange(function (value) {
		controllerLineStartZ = value;
	});

	gui.add(vertexEnd, 'x', -1000, 1000, 1).name('Controller Line End X').onChange(function (value) {
		controllerLineEndX = value;
	});

	gui.add(vertexEnd, 'y', -1000, 1000, 1).name('Controller Line End Y').onChange(function (value) {
		controllerLineEndy = value;
	});

	gui.add(vertexEnd, 'z', -22000, 22000, 1).name('Controller Line End Z').onChange(function (value) {
		controllerLineEndZ = value;
	});
	*/


}